export function setUserId(userId) {
  if (process.env.TARO_ENV === 'weapp') {
    // 小程序暂时不需要这个埋点
    return;
  }
  if (window._horizon && window._horizon.set_user_id) {
    window._horizon.set_user_id(userId);
  } else {
    init(setUserId.bind(this, userId));
  }
}

/**
 * 初始化 horizon 日志上报
 * @param {func} callBack 成功加载后回调
 */
export function init(callBack) {
  if (process.env.TARO_ENV === 'weapp') {
    // 小程序暂时不需要这个埋点
    return;
  }
  (function () {
    const t = document.createElement('script');
    let dataAccount = 'txc.development';
    if (process.env.NODE_ENV === 'production') {
      dataAccount = 'txc.production';
    }
    t.type = 'text/javascript';
    t.async = true;
    t.id = 'horizon-tracker';
    t.setAttribute('data-account', dataAccount);
    t.setAttribute('auto-track', 'false');
    if (callBack) {
      t.onload = callBack;
    }
    t.src = 'https://horizon-assets.qq.com/analytics.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(t, s);
  }());
}
