import Aegis from '@tencent/aegis-web-sdk';
class AegisUtils {
  constructor() {
    this.aegis = new Aegis({
      id: 'YNwAyjjxllivmjMugC', // 项目key
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
    });
  }

  // 设置用户ID
  setUserId(userId) {
    this.aegis.setConfig({
      uin: userId,
    });
  }

  report({ ea, ev, ec, el }) {
    this.aegis.reportEvent({
      name: ea,
      ext1: ev,
      ext2: ec,
      ext3: el,
    });
  }
}

export default new AegisUtils();
